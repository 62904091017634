import * as React from 'react';
import { Link } from 'gatsby';
import { useServices } from '../hooks/useServices';
import getServiceRoute from '../lib/routing/getServiceRoute';

export default function Services() {
	const services = useServices();

	return (
		<div className="container pb-8">
			<h1>ALL SERVICES</h1>
			{services.map(({ node }) => (
				<div key={node.frontmatter.name}>
					<Link to={getServiceRoute(node.frontmatter.name)}>{node.frontmatter.name}</Link>
				</div>
			))}
		</div>
	);
}
